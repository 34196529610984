import React, { useEffect, useState } from "react";
import LocationIcon from "../static/icons/Location.svg";
import CalendarIcon from "../static/icons/UurroosterCalendar.svg";
import BreakIcon from "../static/icons/Break.svg";
import DimonaSuccessIcon from "../static/icons/DimonaSuccess.svg";
import DimonaFailedIcon from "../static/icons/DimonaFail.svg";
import DimonaWarningIcon from "../static/icons/DimonaWarning.svg";
import DimonaPendingIcon from "../static/icons/DimonaPending.svg";
import ExportIcon from "../static/icons/export.svg";
import LeaveIcon from "../static/icons/addLeave.svg";
import RedIcon from "../static/icons/RedDot.svg";
import LeftArrowIcon from "../static/icons/LeftArrow.png";
import RightArrowIcon from "../static/icons/RightArrow.png";
import Dropdown from "../components/atoms/Dropdown";
import { APICALL as AXIOS } from "../services/AxiosServices";
import { LocationApiUrl, UnAuthUurroosterApiUrl, UurroosterApiUrl } from "../routes/ApiEndPoints";
import { GetFormattedDate, GetListFromArray, getFormattedDropdownOptions } from "../utilities/CommonFunctions";
import QRCode from "react-qr-code";
import { t } from "../translations/Translation";
import DateInput from "../components/atoms/formFields/DateInput";
import EmployeeType_icon from "../static/icons/EmployeeType_icon";
import DeviationIcon from "../static/icons/deviation.png";
import { Tooltip as ReactTooltip } from "react-tooltip";


export default function Uurrooster({ view, showData }) {

    const [planData, setPlanData] = useState([]);

    const [locations, setLocations] = useState([]);
    const [selectedLoc, setSelectedLoc] = useState();
    const [qrcode, setQrcode] = useState('');
    const currentDate = new Date();
    const [refresh, setRefresh] = useState(false);

    const Months = [t("JANUARY"), t("FEBRUARY"), t("MARCH"), t("APRIL"), t("MAY"), t("JUNE"), t("JULY"), t("AUGUST"), t("SEPTEMBER"), t("OCTOBER"), t("NOVEMBER"), t("DECEMBER")]
    const [dayData, setDayData] = useState(currentDate.getDate() + ' ' + Months[currentDate.getMonth()] + ', ' + currentDate.getFullYear());
    const [date, setDate] = useState(new Date());
    const [dayDate, setDayDate] = useState(GetFormattedDate(currentDate, currentDate.getFullYear()));
    const [defaultLocationName, setDefaultLocationName] = useState('');
    const [locationStatus, setLocationStatus] = useState(true);
    const [loadDone, setLoadDone] = useState(true);
    const [Time, setTime] = useState(new Date().toLocaleTimeString("sv", { timeZone: "Europe/Paris", hour: '2-digit', minute: '2-digit' }));

    // useEffect(() => {
    //     if (view !== 'login') {
    //         AXIOS.service(LocationApiUrl, 'GET')
    //             .then((res) => {
    //                 setLocations(getFormattedDropdownOptions(res.data, 'id', 'location_name'))
    //                 setSelectedLoc({ value: res.data?.[0]?.id, label: res.data?.[0]?.location_name })
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             })
    //     }
    // }, [view])

    useEffect(() => {
        setPlanData([])

        let data = {
            "location_id": selectedLoc?.value,
            "date": dayDate
        }
        let ApiUrl = UurroosterApiUrl
        if (view === 'login') {
            data = {
                "location_id": selectedLoc?.value,
                "date": dayDate,
                "access_key": localStorage.getItem('dashboard_access_token')
            }
            ApiUrl = UnAuthUurroosterApiUrl
        }

        AXIOS.service(ApiUrl, 'POST', data)
            .then((result) => {
                if (result.success) {
                    setPlanData([])
                    let resp = result.data
                    setQrcode(resp.qr_token);
                    setDefaultLocationName(resp?.location_name)
                    setLocationStatus(resp?.location_selection)
                    setLocations(result.data.locations)
                    setSelectedLoc(result.data?.location)
                    let arr = []
                    let data = resp.planning_data
                    if (data.length !== 0) {
                        data.map((val, index) => {
                            if (val.plannings?.length !== 0) {
                                let plancount = 0
                                //this is to identify the index of planData when new workstation is iterated to add cout as plancount
                                let planindex = arr.length
                                val.plannings.map((employee, emp_index) => {
                                    for (let i = 0; i < (employee?.count === 0 ? 1 : employee?.count); i++) {
                                        //this is to count the plans so that we can adjust the row span of workstion
                                        if (i == 0) {
                                            plancount = plancount + (employee?.count === 0 ? 1 : employee?.count);
                                        }
                                        if (emp_index === 0 && i === 0) {
                                            let design = {
                                                'workstation_name': val.workstation_name,
                                                'count': val.count,
                                                'employee_name': employee.employee_name,
                                                'employee_type': employee.employee_type,
                                                'employee_type_color': employee.employee_type_color,
                                                'function_name': employee?.function_name,
                                                'start_time': employee?.start_time,
                                                'count_2': employee?.count === 0 ? 1 : employee?.count,
                                                'actual_start_time': employee?.actual_start_timings[i],
                                                'dimona_start': { status: employee?.start_dimona_status[i]?.status, message: GetListFromArray(employee?.start_dimona_status[i]?.message) },
                                                'break_time': GetListFromArray(employee?.break_timings),
                                                'end_time': employee?.end_time,
                                                'actual_end_time': employee?.actual_end_timings[i],
                                                'deviation': employee?.deviation[i],
                                                'dimona_end': { status: employee?.end_dimona_status[i]?.status, message: GetListFromArray(employee?.end_dimona_status[i]?.message) },
                                                'cost': employee?.cost,
                                                'leave': employee?.absence_status,
                                                'holiday_code': employee?.absence_holiday_codes
                                            }
                                            arr.push(design);
                                        } else if (emp_index !== 0 && i === 0) {
                                            let design = {
                                                'workstation_name': "",
                                                'count': "",
                                                'employee_name': employee.employee_name,
                                                'employee_type': employee.employee_type,
                                                'employee_type_color': employee.employee_type_color,
                                                'function_name': employee?.function_name,
                                                'start_time': employee?.start_time,
                                                'count_2': employee?.count === 0 ? 1 : employee?.count,
                                                'actual_start_time': employee?.actual_start_timings[i],
                                                'dimona_start': { status: employee?.start_dimona_status[i]?.status, message: GetListFromArray(employee?.start_dimona_status[i]?.message) },
                                                'break_time': GetListFromArray(employee?.break_timings),
                                                'end_time': employee?.end_time,
                                                'actual_end_time': employee?.actual_end_timings[i],
                                                'deviation': employee?.deviation[i],
                                                'dimona_end': { status: employee?.end_dimona_status[i]?.status, message: GetListFromArray(employee?.end_dimona_status[i]?.message) },
                                                'cost': employee?.cost,
                                                'leave': employee?.absence_status,
                                                'holiday_code': employee?.absence_holiday_codes
                                            }
                                            arr.push(design);
                                        } else {
                                            let design = {
                                                'workstation_name': "",
                                                'count': "",
                                                'employee_name': "",
                                                'employee_type': "",
                                                'employee_type_color': "",
                                                'function_name': "",
                                                'start_time': "",
                                                'count_2': "",
                                                'actual_start_time': employee?.actual_start_timings[i],
                                                'dimona_start': { status: employee?.start_dimona_status[i]?.status, message: GetListFromArray(employee?.start_dimona_status[i]?.message) },
                                                'break_time': '',
                                                'end_time': "",
                                                'actual_end_time': employee?.actual_end_timings[i],
                                                'deviation': employee?.deviation[i],
                                                'dimona_end': { status: employee?.end_dimona_status[i]?.status, message: GetListFromArray(employee?.end_dimona_status[i]?.message) },
                                                'cost': "",
                                                'leave': employee?.absence_status,
                                                'holiday_code': employee?.absence_holiday_codes
                                            }
                                            arr.push(design);
                                        }
                                    }
                                })
                                arr[planindex].count = plancount
                            } else {
                                let design = {
                                    'workstation_name': val.workstation_name,
                                    'count': val.count,
                                    'employee_name': "",
                                    'employee_type': "",
                                    'employee_type_color': "",
                                    'function_name': "",
                                    'start_time': "",
                                    'count_2': 1,
                                    'actual_start_time': "",
                                    'dimona_start': "",
                                    'break_time': "",
                                    'end_time': "",
                                    'actual_end_time': "",
                                    'deviation': "",
                                    'dimona_end': "",
                                    'cost': "",
                                    'leave': "",
                                    'holiday_code': ""
                                }
                                arr.push(design);
                            }
                        })
                        setPlanData(arr)
                    }
                    setLoadDone(true);
                }
            })
            .catch((error) => {
                setLoadDone(true);
                console.log(error);
            })

        const interval = setInterval(() => {
            let newTime = new Date().toLocaleTimeString("sv", {
                timeZone: "Europe/Paris",
                hour: '2-digit',
                minute: '2-digit',
            });
            //check for minute change and current date then set Time also
            if (newTime !== Time && dayDate === GetFormattedDate(currentDate, currentDate.getFullYear())) {
                setTime(newTime);
                if (newTime === "00:00") {
                    setDayDate(GetFormattedDate(currentDate, currentDate.getFullYear()))
                }
            }
        }, 1000); // Check every second

        return () => clearInterval(interval);

    }, [dayDate, refresh, view, Time]);

    // Next or previous arrow action
    const setNextPrev = (type) => {
        if (type === 'prev') {
            const [day, month, year] = dayDate.split('-');
            let date_obj = new Date(year, month - 1, day)
            const prevDate = new Date(date_obj);
            prevDate.setDate(date_obj.getDate() - 1);
            setDate(prevDate);
            setDayDate(GetFormattedDate(prevDate, prevDate.getFullYear()))
            setDayData(prevDate.getDate() + ' ' + Months[prevDate.getMonth()] + ', ' + prevDate.getFullYear())
        } else {
            const [day, month, year] = dayDate.split('-');
            let date_obj = new Date(year, month - 1, day)
            const nextDate = new Date(date_obj);
            nextDate.setDate(date_obj.getDate() + 1);
            setDayDate(GetFormattedDate(nextDate, nextDate.getFullYear()))
            setDate(nextDate)
            setDayData(nextDate.getDate() + ' ' + Months[nextDate.getMonth()] + ', ' + nextDate.getFullYear())
        }
    }

    useEffect(() => {
        const [day, month, year] = dayDate.split('-');
        let date_obj = new Date(year, month - 1, day)
        setDayData(date_obj.getDate() + ' ' + Months[date_obj.getMonth()] + ', ' + date_obj.getFullYear())
    }, [dayDate])


    return (
        showData === 'qrcode' ?
            <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "40%", width: "50%" }}
                viewBox={`0 0 256 256`}
                value={qrcode}
            ></QRCode>
            :
            <div className={view === "login" ? "w-100" : "right-container"}>
                <div className={"border bg-white " + (view === 'login' ? "w-100" : "company-tab-width mt-3")}>
                    <div className="col-md-12 d-flex mt-4">
                        <div className="col-md-3">
                            <p className=""><img className="mr-2 planning-icon" src={LocationIcon} alt={t("LOCATION_TITLE")}></img>{selectedLoc ? selectedLoc?.label : defaultLocationName}</p>
                            <p className=""><img className="mr-2 planning-icon" src={CalendarIcon} alt={t("CALENDAR")}></img>{dayData}</p>
                            {/* <img className="" src={RedIcon} alt={t("ICON")}></img> */}
                        </div>
                        <div className="col-md-6">
                            {locationStatus && <p className="text-center mb-0 mx-auto col-md-8 p-0 font-weight-bold">{t("SELECT_LOCATION")}</p>}
                            {locationStatus && <Dropdown
                                options={locations}
                                selectedOptions={selectedLoc}
                                onSelectFunction={(e) => { setSelectedLoc(e); setRefresh(!refresh) }}
                                CustomStyle="col-md-8 my-2 px-0 mx-auto pointer"
                            ></Dropdown>}
                            {!locationStatus && <p className="text-center mb-0 mx-auto col-md-8 p-0 font-weight-bold">{t("SELECT_DATE")}</p>}
                            <div className="d-flex mt-1 border col-md-8 p-0 mx-auto">
                                <div className={loadDone ? "button-style" : "button-style disabled-icon"} onClick={() => { loadDone && setNextPrev('prev'); setLoadDone(false); }}><img className="planning-icon pointer" src={LeftArrowIcon} alt={t("PREV_ARROW")}></img></div>
                                <DateInput
                                    key={'calendar'}
                                    title={''}
                                    name={'date'}
                                    CustomStyle={'w-100'}
                                    value={dayDate}
                                    setValue={(e) => setDayDate(e)}
                                ></DateInput>
                                <div className={loadDone ? "button-style" : "button-style disabled-icon"} onClick={() => { loadDone && setNextPrev('next'); setLoadDone(false); }}><img className="planning-icon pointer" src={RightArrowIcon} alt={t("NEXT_ARROW")}></img></div>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex justify-content-end">
                            <div className="float-left">
                                {/* <img className="mr-4 pointer" src={FilterIcon} alt={t("FILTER")} title={t("FILTER")}></img> */}
                                {/* <br></br> */}
                                <img className="mr-4 pointer disabled-icon" src={ExportIcon} alt={t("EXPORT")} data-tooltip-id={t("EXPORT")} ></img>
                                <ReactTooltip
                                    id={t("EXPORT")}
                                    place="top"
                                    globalEventOff="click"
                                    content={t("EXPORT")}
                                />
                            </div>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "40%", width: "50%" }}
                                viewBox={`0 0 256 256`}
                                value={qrcode}
                            ></QRCode>
                        </div>
                    </div>
                    <div className="mt-3 uurrooster_table">
                        <table className="table table-bordered company-tab-width mx-auto table-fixed ">
                            <thead className="button-style uurrooster-table">
                                <tr>
                                    <th rowSpan="2" className="w-12">{t('WORKSTATION')}</th>
                                    <th rowSpan="2" className="w-12">{t('EMPLOYEE_TITLE')}</th>
                                    <th rowSpan="2" className="w-12">{t('FUNCTION_TITLE')}</th>
                                    <th colSpan="3">{t('START_WORK_TITLE')}</th>
                                    <th rowSpan="2">{t('PAUSE_TITLE')}</th>
                                    <th colSpan="3">{t('END_WORK_TITLE')}</th>
                                    <th rowSpan="2">{t('TOTAL_TITLE')}</th>
                                </tr>
                                <tr>
                                    <th>{t('PLANNING_TITLE')}</th>
                                    <th>{t('STARTED_TITLE')}</th>
                                    <th>{t('DIMONA')}</th>
                                    <th>{t('PLANNING_TITLE')}</th>
                                    <th>{t('STOPPED_TITLE')}</th>
                                    <th>{t('DIMONA')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {planData.map((val, index) => {
                                    if (val.workstation_name && val.employee_name === "") {
                                        return (
                                            <tr key={val.workstation_name}>
                                                {val.workstation_name && <td rowSpan={val.count} className="text-truncate" data-tooltip-id={val.workstation_name}>{val.workstation_name}
                                                    <ReactTooltip
                                                        id={val.workstation_name}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={val.workstation_name}
                                                    /></td>
                                                }
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        )
                                    } else {
                                        console.log(val.employee_name, val.employee_type, val.workstation_name, val.holiday_code, val.function_name, val.dimona_start?.message, val.dimona_end?.message, val.break_time);
                                        return (
                                            <tr key={val.workstation_name}>
                                                {val.workstation_name && <td rowSpan={val.count} className="text-truncate" data-tooltip-id={val.workstation_name}>{val.workstation_name}
                                                    <ReactTooltip
                                                        id={val.workstation_name}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={val.workstation_name}
                                                    />
                                                </td>}

                                                {val.employee_name && <td className="text-left text-truncate" rowSpan={val.count_2}>
                                                    <span className="mr-3" data-tooltip-id={val.employee_type}>
                                                        <EmployeeType_icon IconColour={val.employee_type_color} width="25" height="25" />
                                                        <ReactTooltip
                                                            id={val.employee_type}
                                                            place="top"
                                                            globalEventOff="click"
                                                            content={val.employee_type}
                                                        />
                                                    </span>
                                                    <span data-tooltip-id={val.employee_name}>{val.employee_name}
                                                        <ReactTooltip
                                                            id={val.employee_name}
                                                            place="top"
                                                            globalEventOff="click"
                                                            content={val.employee_name}
                                                        />
                                                    </span>
                                                    <div className="text-center">
                                                        {val.leave && <img className="mt-1" data-tooltip-id={val.holiday_code !== null ? val.holiday_code : ""} src={LeaveIcon} alt="leave"></img>}
                                                        <ReactTooltip
                                                            id={val.holiday_code}
                                                            place="top"
                                                            globalEventOff="click"
                                                            content={val.holiday_code}
                                                        />
                                                    </div>
                                                </td>}

                                                {val.function_name && <td rowSpan={val.count_2} className="text-truncate" data-tooltip-id={val.function_name}>{val.function_name.substring(0, 15) + " ..."}
                                                    <ReactTooltip
                                                        id={val.function_name + index}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={val.function_name}
                                                    />
                                                </td>}

                                                {val.start_time && <td rowSpan={val.count_2}>{val.start_time}</td>}
                                                {<td>{val.actual_start_time}</td>}
                                                {val.dimona_start ? <td>{![null, undefined].includes(val.dimona_start?.status) &&
                                                    <img data-tooltip-id={'dimona_start' + index} src={val.dimona_start?.status === 'success' ? DimonaSuccessIcon : val.dimona_start?.status === 'pending' ? DimonaPendingIcon : val.dimona_start?.status === 'warning' ? DimonaWarningIcon : DimonaFailedIcon} alt="dimona start"></img>}
                                                    <ReactTooltip
                                                        id={'dimona_start' + index}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={val.dimona_start?.message}
                                                    />
                                                </td> : <td></td>}

                                                {val.break_time?.length !== 0 ? <td rowSpan={val.count_2}>
                                                    <img src={BreakIcon} data-tooltip-id={'breaktime' + index} alt="break time"></img>
                                                    <ReactTooltip
                                                        id={'breaktime' + index}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={val.break_time}
                                                    />
                                                </td> : <td></td>}

                                                {val.end_time && <td rowSpan={val.count_2}>{val.end_time}</td>}
                                                {<td>{val.deviation && <img className="header-icon" src={DeviationIcon} alt="deviation"></img>}{val.actual_end_time}</td>}
                                                {val.dimona_end ? <td>{![null, undefined].includes(val.dimona_end?.status) &&
                                                    <img data-tooltip-id={'dimona_end' + index} src={val.dimona_end?.status === 'success' ? DimonaSuccessIcon : val.dimona_end?.status === 'pending' ? DimonaPendingIcon : val.dimona_end?.status === 'warning' ? DimonaWarningIcon : DimonaFailedIcon} alt="dimona end"></img>}
                                                    <ReactTooltip
                                                        id={'dimona_end' + index}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={val.dimona_end?.message}
                                                    />
                                                </td> : <td></td>}
                                                {val.cost ? <td rowSpan={val.count_2}>{val.cost}</td> : <td></td>}
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
    )
}
