import React, { useState, useEffect } from "react";
import Table from "../atoms/Table";
import { APICALL as axios } from "../../services/AxiosServices";
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetEmployeeDocumentsApiUrl, UploadEmployeeDocumentApiUrl } from "../../routes/ApiEndPoints";
// import UpdateIdCardPopup from "./UpdateIdCardPopup";
import CustomButton from "../atoms/CustomButton";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import CustomPopup from "../../utilities/popup/CustomPopup"
import FileInput from "../atoms/FileInput";
import { toast } from "react-toastify";

export default function EmployeeDocumentsOverview({ eid }) {

    const [documentsData, setDocumentsData] = useState([])
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];
    const [showIdcardPopup, setShowIdcardPopup] = useState(false)
    const [show, setShow] = useState(false)
    const [dataRefresh, setDataRefresh] = useState(false)
    const [errors, setErrors] = useState('')
    const [formData, setFormData] = useState({})


    const tableHeaders = [
        {
            title: t("DOCUMENT_NAME"),
            field: "file_name",
            status: "200",
        },
        {
            title: t("TYPE"),
            field: "type",
            status: "200",
        },
    ]

    useEffect(() => {
        AXIOS.service(GetEmployeeDocumentsApiUrl + "/" + eid, 'GET')
            .then((result) => {
                if (result?.success) {
                    let arr = []
                    result.data.map((item, index) => {
                        item.id = item.file_id
                        arr.push(item)
                    })
                    setDocumentsData(arr)
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [eid, dataRefresh])

    const setVAlue = (index, name, file) => {
        setFormData({ ...formData, [name]: file })
    }

    const onUpload = () => {
        let data = new FormData()
        data.append('file', formData["new_file"])
        data.append('employee_profile_id', eid)
        AXIOS.service(UploadEmployeeDocumentApiUrl, 'POST', data, true)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setDataRefresh(!dataRefresh)
                    setShow(false)
                    setFormData({})
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    })
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const onHide = () => {
        setShow(false)
        setFormData({})
    }


    const viewAction = (data, action) => {

        if (action === 'view') {
            window.open(data.file_url, '_blank')
        }

    }

    return (
        <>
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("WARNING_TITLE") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {show &&

                <CustomPopup header title={t("UPLOAD_NEW")} footer size="lg" onHide={onHide} onConfirm={onUpload} saveButtonName={t('UPLOAD')} body={
                    <div className="popup-tabs border">
                        <FileInput CustomStyle="p-5" name={'new_file'} setValue={setVAlue} accept={".pdf, image/png, .doc, image/jpeg"}>
                        </FileInput>
                    </div>
                }></CustomPopup>}
            {/* {showIdcardPopup && <UpdateIdCardPopup show={showIdcardPopup} setShow={setShowIdcardPopup} onHide={() => setShowIdcardPopup(false)} eid={eid} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh}></UpdateIdCardPopup>} */}
            <div className="d-flex  justify-content-end  mt-2  mb-2 w-100">
                {/* <CustomButton buttonName={t("UPDATE_ID_CARD")} ActionFunction={() => setShowIdcardPopup(true)}></CustomButton> */}
                <CustomButton buttonName={t("UPLOAD_NEW")} ActionFunction={() => setShow(true)}></CustomButton>
            </ div>
            <Table columns={tableHeaders} rows={documentsData} tableName={"documents_overview"} viewAction={viewAction} permission={UserPermissions?.includes('modify_employee_details')} viewPermission={UserPermissions?.includes('modify_employee_details')}></Table>
        </>
    )
}